<template>
  <loader v-if="loading"/>
  <alert-page v-else-if="error" :img="require('@/assets/generic-faliure.png')"
              show-button
              title=""
              button-title="לתזרים"
              :button-action="redirectToCF">
    <template v-slot:content>
      <div class="bold margin-top-l">
        איזה כיף שקיבלת ערך מרייזאפ!
      </div>
      <div class="margin-top-s">
        הטבת ״חבר״ נועדה לשימוש אחד לכל עמית ועמיתה, ולכן לא ניתן יהיה לממש אותה שוב
      </div>

    </template>
  </alert-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import Loader from '@/base-components/Loader';
import router from '@/router';
import DDLogs from '@/DDLogs';
import Segment from '@/Segment';
import AlertPage from '@/base-components/AlertPage.vue';

const FlowStates = {
  MonthlySubscription: 'MonthlySubscription',
  OtherSubscription: 'OtherSubscription',
  OtherNotSubscribed: 'OtherNotSubscribed',
  PreTrial: 'PreTrial',
};

export default {
  name: 'GenerateBenefitFlow',
  components: {
    AlertPage,
    Loader,
  },
  props: {
    promoSource: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      error: false,
    };
  },
  async created() {
    Segment.trackUserGot('GenerateBenefitFlow_Entered', { promoSource: this.promoSource });
    try {
      await this.fetchSubscription();

      let flowState;
      if (this.subscribed) {
        if (this.isMonthlySubscription) {
          flowState = FlowStates.MonthlySubscription;
        } else {
          flowState = FlowStates.OtherSubscription;
        }
      } else if (!this.preTrial) {
        flowState = FlowStates.OtherNotSubscribed;
      } else {
        flowState = FlowStates.PreTrial;
      }

      const segmentEventData = {
        promoSource: this.promoSource,
        flowState,
        subscribed: this.subscribed,
        isMonthlySubscription: this.isMonthlySubscription,
        preTrial: this.preTrial,
      };
      Segment.trackUserGot('GenerateBenefitFlow_FlowState', segmentEventData);

      const benefitCreationResult = await this.createBenefitByPromoSource({ promoSource: this.promoSource, pending: false });
      segmentEventData.benefitCreationResult = benefitCreationResult;
      Segment.trackUserGot('GenerateBenefitFlow_CreatedBenefit', segmentEventData);
      if (benefitCreationResult === this.BENEFIT_CREATION_STATUS.CUSTOMER_LIMIT_EXCEEDED
          || benefitCreationResult === this.BENEFIT_CREATION_STATUS.EXISTING_NON_REDEEMED_BENEFIT) {
        Segment.trackUserGot('GenerateBenefitFlow_CustomerLimitExceeded', segmentEventData);
        // eslint-disable-next-line no-unused-vars
        const { promoSource, ...query } = this.$route.query;
        this.$router.replace({ path: this.$route.path, query });
        this.error = true;
        this.loading = false;
        return;
      }

      switch (flowState) {
      case FlowStates.MonthlySubscription: {
        switch (benefitCreationResult) {
        case this.BENEFIT_CREATION_STATUS.SUCCESS:
        case this.BENEFIT_CREATION_STATUS.EXISTING_NON_REDEEMED_BENEFIT: {
          Segment.trackUserGot('GenerateBenefitFlow_HasMonthlySubscription', segmentEventData);
          const success = await this.updateSubscriptionV2();
          if (!success) {
            Segment.trackUserGot('GenerateBenefitFlow_RecreateSubscriptionFailed', segmentEventData);
            router.push({ path: '/sr' });
            break;
          }
          Segment.trackUserGot('GenerateBenefitFlow_RecreatedSubscription', segmentEventData);
          await this.redeemBenefit();
          Segment.trackUserGot('GenerateBenefitFlow_RedeemedBenefit', segmentEventData);
          router.push({ path: '/ambassadors/partners' });
          break;
        }
        case this.BENEFIT_CREATION_STATUS.CUSTOMER_LIMIT_EXCEEDED: {
          Segment.trackUserGot('GenerateBenefitFlow_CustomerLimitExceeded', segmentEventData);
          router.push({ path: '/sr' });
          break;
        }
        default: {
          Segment.trackUserGot('GenerateBenefitFlow_BenefitCreationError', segmentEventData);
          router.push({ path: '/sr' });
          break;
        }
        }
        break;
      }
      case FlowStates.OtherSubscription: {
        Segment.trackUserGot('GenerateBenefitFlow_OtherSubscription', segmentEventData);
        DDLogs.error('GenerateBenefitFlow_OtherSubscription', segmentEventData);
        router.push({ path: '/sr' });
        break;
      }
      case FlowStates.OtherNotSubscribed: {
        Segment.trackUserGot('GenerateBenefitFlow_OtherNotSubscribed', segmentEventData);
        router.push({ path: '/ambassadors/partners' });
        break;
      }
      case FlowStates.PreTrial: {
        Segment.trackUserGot('GenerateBenefitFlow_PreTrial', segmentEventData);
        router.push({ path: '/sr' });
        break;
      }
      default: {
        Segment.trackUserGot('GenerateBenefitFlow_Impossible', segmentEventData);
        DDLogs.error('GenerateBenefitFlow_Impossible', segmentEventData);
        router.push({ path: '/sr' });
        break;
      }
      }
    } catch (e) {
      DDLogs.error('Failed to generate benefit from GenerateBenefitFlow', { error: e, promoSource: this.promoSource });
      Segment.trackUserGot('GenerateBenefitFlow_Error', { promoSource: this.promoSource });
      router.push({ path: '/sr' });
    }
  },
  computed: {
    ...mapGetters('subscriptionState', ['subscribed', 'preTrial']),
    ...mapGetters('subscription', ['isMonthlySubscription']),
    ...mapGetters('benefits', ['BENEFIT_CREATION_STATUS']),
  },
  methods: {
    ...mapActions('benefits', ['createBenefitByPromoSource', 'redeemBenefit']),
    ...mapActions('subscription', ['updateSubscriptionV2', 'fetchSubscription']),
    redirectToCF() {
      router.push({ path: '/sr' });
    },
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: bold;
}
</style>
