<template>
  <div class="timer-background">
    <div v-on:click="clicked" class="offer-timer border-radius-medium">
      <div class="timer-and-title">
        <div class="timer-header">{{ title }}</div>
        <time-limited-offer-timer-counter :end-date="endDate" variant:/>
      </div>
    </div>
  </div>
</template>

<script>
import Segment from '@/Segment';
import TimeLimitedOfferTimerCounter from './TimeLimitedOfferTimerCounter';

export default {
  name: 'TimeLimitedOfferTimerSmall',
  components: {
    TimeLimitedOfferTimerCounter,
  },
  props: {
    endDate: { type: Object, require: true },
    onClick: { type: Function, require: true },
    title: { type: String, require: true },
    offerId: { type: String, require: true },
  },
  created() {
    Segment.trackUserGot('TimeLimitedOffer_shown', { offerId: this.offerId });
  },
  methods: {
    clicked() {
      Segment.trackUserInteraction('TimeLimitedOffer_pressed', { offerId: this.offerId });
      this.onClick();
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@riseupil/base-ui/src/scss/riseup-colors';

.timer-background {
  padding: 30px 24px;

  .offer-timer {
    margin: 0px 0 0px;
    position: relative;
    overflow: hidden;
    height: 92px;
    font-size: 16px;
    font-family: SimplerPro, sans-serif;
    cursor: pointer;
    line-height: 24px;
    background: $riseup_green;
    background-image: url('~@/assets/ntd/ntd-banner.png');
    background-size: contain;
    background-repeat: no-repeat;
    box-shadow: 0px 5px 8px 0px rgba(186, 188, 191, 0.25);
    display: flex;
    .timer-and-title {
      ::v-deep .number, ::v-deep .divider {
        font-size: 36px;
      }

      ::v-deep .number {
        min-width: 40px;
      }

      padding: 14px 12px;
      text-align: right;

      .timer-header {
        font-size: 16px;
        line-height: 20px;
        font-weight: bold;
        color: white;
      }
    }
  }
}
</style>
