<template>
  <popup :close-action="closeActionClicked" :buttons="buttons" class="suggest-addons-cta-popup">
    <template v-slot:title>
      <div class="padding-top-ml">
        <trial-state-snackbar :close-modal="() => undefined" :hide-arrow="true" />
        <img class="cta-image margin-top-l margin-bottom-sm" src="/images/suggest-offerings-popup/suggest-offerings.png" />
        <div class="ri-large-headline">קל יותר להתחיל הרגל חדש כשעושים את זה ביחד</div>
        <div class="margin-top-s ri-large-body">
          בין אם מתאים לך הכח של הקבוצה או פגישה אינטימית ואישית, לרייזאפ יש תוכניות שיאפשרו לך להתמודד עם הכסף שלך ביחד ולא לבד</div>
      </div>
    </template>
  </popup>
</template>

<script>
import BaseUI from '@riseupil/base-ui';
import { mapActions, mapGetters } from 'vuex';
import TrialStateSnackbar from '@/base-components/app-header/TrialStateSnackbar';
import Segment from '@/Segment';

export default {
  name: 'SuggestOfferingsCtaPopup',
  components: {
    TrialStateSnackbar,
    Popup: BaseUI.Popup,
  },
  created() {
    Segment.trackUserInteraction('SuggestOfferingsCtaPopupShown', { trialPeriod: this.trialPeriod });
  },
  computed: {
    ...mapGetters('subscriptionState', ['trialPeriod']),
    buttons() {
      return [{
        title: 'לתוכניות הליווי של רייזאפ',
        action: () => {
          Segment.trackUserInteraction('SuggestOfferingsCtaPopup_ShowOfferingsButtonClicked', { trialPeriod: this.trialPeriod });
          this.$router.push({ path: '/offerings-v2' });
          this.$nextTick(() => {
            this.suggestOfferingsCtaDismissed();
          });
        },
        variant: 'primary',
        size: 'slim',
      }];
    },
  },
  methods: {
    ...mapActions('suggestOfferingsPopup', ['ctaDismissed']),
    closeActionClicked() {
      Segment.trackUserInteraction('SuggestOfferingsCtaPopup_CloseButtonClicked', { trialPeriod: this.trialPeriod });
      this.suggestOfferingsCtaDismissed();
    },
    suggestOfferingsCtaDismissed() {
      this.ctaDismissed();
      this.$emit('close');
    },
  },
};
</script>

<style scoped lang="scss">
  @import '~@riseupil/base-ui/src/scss/riseup-colors';
  @import '~@riseupil/base-ui/src/scss/border-radius';

  .suggest-addons-cta-popup {
    border-radius: $popup-border-radius;
    background-color: $riseup_beige;
    .cta-image{
      height: 100%;
      width: 100%;
    }
  }
</style>
